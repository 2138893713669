import * as React from "react"

const NotFoundPage = () => {
  return (
    <>
      <h2 className='page-heading'>Page Not Found</h2>
      <p className='pt-3'>Please check the URL in the address bar and try again.</p>
    </>
  )
}

export default NotFoundPage
